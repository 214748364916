<template>

  <!-- Competitors -->
  <div id="story-mode-competitors">

    <!-- Main Navigation -->
    <main-navigation />
    <!-- Main Navigation -->

    <!-- Container -->
    <div class="container-1200">

      <!-- Content -->
      <div
        class="content"
        :class="{ 'position-relative': statusOfCompleted }"
      >

        <v-app>
          <!-- Page Header -->
          <header class="page-header">

            <!-- Dashboard Header -->
            <dashboard-header
              :class="{ 'stamp': statusOfCompleted }"
              :title="$t('steps.research.items.competitors')"
              :guide-visible="guideVisible"
              :completed-step-name="'competitorsCompleted'"
              hide-quick-tour-button
              @toggle-guide="onGuideExampleVisible"
              @open-comp-dialog="openedDialogProcessCompleted"
              @open-info="guideVisible = true"
              @open-ai-buddy-drawer="aiBuddyDrawerVisible = true"
            />
            <!-- /Dashboard Header -->

            <!-- Divider -->
            <hr>
            <!-- /Divider -->

          </header>
          <!-- /Page Header -->

          <!-- Ai Buddy Drawer -->
          <ai-buddy-drawer
            :visible="aiBuddyDrawerVisible"
            section-name="Competitors"
            help-me-write
            type="competitors"
            @close="aiBuddyDrawerVisible = false"
          />
          <!-- /Ai Buddy Drawer -->
        </v-app>

        <!-- Business plan additional info -->
        <business-plan-additional-info
          completed-step-name="competitorsCompleted"
          section-name="Competitors"
          show-ai-buddy
          @ai-buddy-open="aiBuddyDrawerVisible = true"
        />
        <!-- /Business plan additional info -->

        <!-- Height Holder For Sticky Header -->
        <div class="height-holder d-none d-md-block" />
        <!-- /Height Holder For Sticky Header -->

        <v-app><!-- Header -->
          <business-guide-header>
            <template #left>
              <h2>{{ $t('pages.businessGuide.competitors.keyCompetitors') }}</h2>
              <p class="text-lead">
                {{ $t('pages.businessGuide.competitors.identifyMajorOpponents') }}
              </p>
            </template>
            <template #right>
              <erase-icon-button
                v-if="removeButtonVisible"
                @click="onEraseFromTemplate"
              />
              <business-guide-search
                v-if="!isCompetitorsEmpty"
                v-model="searchValue"
                class="mx-1"
              />
              <!-- View Switcher -->
              <view-switcher
                v-if="isCompetitorsEmpty === false"
                :active-view="$store.state.itemsView.competitorsView"
                @activate-view="onActivateView"
              />
              <!-- /View Switcher -->
            </template>
          </business-guide-header>
          <!-- /Header --></v-app>

        <!-- Competitors -->
        <div class="competitors competitors-filled">

          <!-- Grid Wrapper -->
          <div class="grid-wrapper" :class="{ 'active': $store.state.itemsView.competitorsView === 'grid' }">

            <el-row :gutter="20">
              <!-- Completed Overlay -->
              <completed-overlay
                v-if="hasNotAccess"
                @click="checkCompetitorsCard"
              />
              <!-- Completed Overlay -->

              <!-- Empty state grid -->
              <div
                v-if="isCompetitorsEmpty"
                class="d-flex flex-wrap"
              >
                <el-col :md="8" :xl="6" class="d-flex mb-3">
                  <add-new-card
                    :title="$tc('newCompetitor')"
                    :disabled="hasNotAccess"
                    @click="dialogAddCompetitorVisible = true"
                  />
                </el-col>

                <!-- Placeholder 1 -->
                <el-col v-if="$breakpoint.mdAndUp" :md="8" :xl="6" class="mb-3">
                  <div class="placeholder-card xlarge">
                    <div class="w-100">
                      <div class="avatar-placeholder blue" />
                      <div class="stripe-medium" />
                      <div class="placeholder-list">
                        <ul>
                          <li />
                          <li />
                          <li />
                          <li />
                          <li />
                        </ul>
                      </div>
                      <div class="stripe-large" />
                    </div>
                  </div>
                </el-col>
                <!-- Placeholder 1 -->

                <!-- Placeholder 2 & Tutorial Tooltip -->
                <el-col v-if="$breakpoint.mdAndUp" :md="8" :xl="6" class="mb-3">
                  <div class="placeholder-card xlarge">
                    <!-- Placeholder 2 -->
                    <div class="w-100">
                      <div class="avatar-placeholder yellow" />
                      <div class="stripe-medium" />
                      <div class="placeholder-list">
                        <ul>
                          <li />
                          <li />
                          <li />
                          <li />
                          <li />
                        </ul>
                      </div>
                      <div class="stripe-large" />
                    </div>
                    <!-- /Placeholder 2 -->
                  </div>
                </el-col>
              </div>
              <!-- /Empty state grid -->

              <!-- Tutorial Tooltip TODO - Currently disabled in if directive -->
              <tutorial-tooltip
                v-if="$store.state.user.tutorial.competitorsCompleted === false && 1 === 2"
                :title="$t('pages.businessGuide.competitors.addCustomersYouWantToTarget')"
                :text="$t('pages.businessGuide.thisIsStarOfYourShow')"
                :tutorial-name="'competitorsCompleted'"
              />
              <!-- /Tutorial Tooltip -->

              <!-- Grid Items -->
              <template v-if="!isCompetitorsEmpty">
                <ib-draggable
                  class="d-flex flex-wrap"
                  :list="competitorsToShowGrid"
                  :options="{disabled: hasNotAccess}"
                  @change="onChangeCompetitorsOrder"
                  @start="onStartCompetitorsOrder"
                >
                  <template #header>
                    <!-- New Competitor -->
                    <el-col :md="8" :xl="6" class="d-flex position-relative mb-3">
                      <add-new-card
                        :title="$tc('newCompetitor')"
                        :disabled="hasNotAccess"
                        @click="dialogAddCompetitorVisible = true"
                      />
                    </el-col>
                    <!-- /New Competitor -->
                  </template>

                  <el-col
                    v-for="competitor in competitorsToShowGrid"
                    :key="competitor.id"
                    class="draggable mb-3"
                    :md="8"
                    :xl="6"
                  >
                    <div
                      class="competitor-card cursor-move"
                      @click="checkCompetitorsCard(competitor)"
                    >

                      <!-- Card Top -->
                      <div
                        class="card-top" :class="{ 'default': !competitor.image }"
                        :style="cardImageBySize(competitor.image, 'medium')"
                      >

                        <!-- Actions Dropdown -->
                        <actions-dropdown
                          v-if="canEdit && !$store.state.idea.storyMode.common.completedSteps.competitorsCompleted"
                          ref="actionsDropdown"
                          :entity="competitor"
                          @edit="onEditCompetitor"
                          @copy="onCopyCompetitor"
                          @delete="onDeleteCompetitor"
                        />
                        <!-- /Actions Dropdown -->

                        <!-- Influence -->
                        <div class="influence" :class="{ 'yellow': competitor.direct }">
                          <p>{{ competitor.direct ? $t('direct') : $t('indirect') }}</p>
                        </div>
                        <!-- /Influence -->

                      </div>
                      <!-- /Card Top -->

                      <!-- Card Bottom -->
                      <div class="card-bottom">

                        <!-- Name -->
                        <h3>{{ competitor.name }}</h3>
                        <!-- /Name -->

                        <p class="desc pre-formatted text-left ml-2 mr-2">
                          {{ competitor.description }}
                        </p>

                        <!-- Competitive Advantages -->
                        <ul>

                          <!-- Advantage 1 -->
                          <li>
                            <p>{{ competitor.advantageTitle1 }}</p>
                            <p class="bold">
                              {{ calculateAdvantageStrength(competitor.advantageValue1) }}
                            </p>
                          </li>
                          <!-- /Advantage 1 -->

                          <!-- Advantage 2 -->
                          <li>
                            <p>{{ competitor.advantageTitle2 }}</p>
                            <p class="bold">
                              {{ calculateAdvantageStrength(competitor.advantageValue2) }}
                            </p>
                          </li>
                          <!-- /Advantage 2 -->

                          <!-- Advantage 3 -->
                          <li>
                            <p>{{ competitor.advantageTitle3 }}</p>
                            <p class="bold">
                              {{ calculateAdvantageStrength(competitor.advantageValue3) }}
                            </p>
                          </li>
                          <!-- /Advantage 3 -->

                          <!-- Advantage 4 -->
                          <li>
                            <p>{{ competitor.advantageTitle4 }}</p>
                            <p class="bold">
                              {{ calculateAdvantageStrength(competitor.advantageValue4) }}
                            </p>
                          </li>
                          <!-- /Advantage 4 -->

                          <!-- Advantage 5 -->
                          <li>
                            <p>{{ competitor.advantageTitle5 }}</p>
                            <p class="bold">
                              {{ calculateAdvantageStrength(competitor.advantageValue5) }}
                            </p>
                          </li>
                          <!-- /Advantage 5 -->

                        </ul>
                        <!-- /Competitive Advantages -->

                        <!-- Threat -->
                        <div
                          class="threat"
                          :class="[getCompetitorLevel(competitor) === $t('medium') ? 'medium': '' || getCompetitorLevel(competitor) === $t('low') ? 'low': '']"
                        >
                          <p>{{ $t('threatLevel') }}</p>
                          <p class="title">
                            {{ getCompetitorLevel(competitor) }}
                          </p>
                        </div>
                        <!-- /Threat -->

                      </div>
                      <!-- /Card Bottom -->
                    </div>
                  </el-col>
                </ib-draggable>
              </template>
            </el-row>

            <!-- Show All -->
            <el-row v-if="$store.state.idea.storyMode.research.competitors.competitors.length > 3">
              <el-col>
                <show-all-toggle
                  :show="showAllCompetitorsGrid"
                  @click="showAllCompetitorsGrid = !showAllCompetitorsGrid"
                />
              </el-col>
            </el-row>
            <!-- /Show All -->
          </div>
          <!-- /Grid Wrapper -->

          <!-- List Wrapper -->
          <div class="list-wrapper" :class="{ 'active': $store.state.itemsView.competitorsView === 'list' }">
            <el-row :gutter="20">
              <!-- Completed Overlay -->
              <completed-overlay
                v-if="hasNotAccess"
                @click="checkCompetitorsCard"
              />
              <!-- Completed Overlay -->

              <!-- List Items -->
              <ib-draggable
                class="d-flex flex-wrap"
                :list="competitorsToShowList"
                :options="{disabled: hasNotAccess}"
                @change="onChangeCompetitorsOrder"
                @start="onStartCompetitorsOrder"
              >
                <template #header>
                  <!-- New Competitor -->
                  <el-col :md="12" class="d-flex mb-2">
                    <add-new-card
                      class="py-2 py-md-0"
                      :title="$tc('newCompetitor')"
                      :disabled="hasNotAccess"
                      horizontal
                      @click="dialogAddCompetitorVisible = true"
                    />
                  </el-col>
                <!-- /New Competitor -->
                </template>

                <el-col
                  v-for="competitor in competitorsToShowList"
                  :key="competitor.id"
                  :md="12"
                  class="draggable mb-2"
                >
                  <div
                    class="competitor-card cursor-move"
                    @click="checkCompetitorsCard(competitor)"
                  >

                    <!-- Card Top -->
                    <div class="card-top">

                      <!-- Card Image, Name & Influence -->
                      <div class="flex-inner">

                        <!-- Card Image -->
                        <div>
                          <div
                            class="card-image-round" :class="{ 'default': !competitor.image }"
                            :style="cardImageBySize(competitor.image, 'small')"
                          />
                        </div>
                        <!-- /Card Image -->

                        <!-- Name & Influence -->
                        <div>

                          <!-- Name -->
                          <h3>{{ competitor.name }}</h3>
                          <!-- /Name -->

                          <!-- Influence -->
                          <p style="float: left; margin-left: 10px;">
                            {{ competitor.direct ? $t('direct') : $t('indirect') }}
                          </p>
                          <!-- /Influence -->

                        </div>
                        <!-- /Name & Influence -->

                      </div>
                      <!-- /Card Image, Name & Influence -->

                      <!-- Threat & Actions Dropdown -->
                      <div class="flex-inner">

                        <!-- Threat -->
                        <div
                          class="threat"
                          :class="[getCompetitorLevel(competitor) === $t('medium') ? 'medium': '' || getCompetitorLevel(competitor) === $t('low') ? 'low': '']"
                        >
                          <p class="title">
                            {{ getCompetitorLevel(competitor) }} <span>{{ $t('pages.businessGuide.competitors.threat') }}</span>
                          </p>
                        </div>
                        <!-- /Threat -->

                        <!-- Actions Dropdown -->
                        <actions-dropdown
                          v-if="canEdit && !$store.state.idea.storyMode.common.completedSteps.competitorsCompleted"
                          ref="actionsDropdown"
                          :entity="competitor"
                          @edit="onEditCompetitor"
                          @copy="onCopyCompetitor"
                          @delete="onDeleteCompetitor"
                        />
                        <!-- /Actions Dropdown -->

                      </div>
                      <!-- /Threat & Actions Dropdown -->

                    </div>
                    <!-- /Card Top -->

                  </div>
                </el-col>
              </ib-draggable>
              <!-- /List Items -->
            </el-row>
            <!-- Show All -->
            <el-row v-if="$store.state.idea.storyMode.research.competitors.competitors.length > 7">
              <el-col>
                <show-all-toggle
                  :show="showAllCompetitorsList"
                  @click="showAllCompetitorsList = !showAllCompetitorsList"
                />
              </el-col>
            </el-row>
            <!-- /Show All -->
          </div>
          <!-- /List Wrapper -->

        </div>
        <!-- /Competitors -->

      </div>
      <!-- /Content -->

      <!-- Add More Details -->
      <add-more-details
        :category="'research'"
        :step="'competitors'"
        :concept-name="'competitorsMoreDetails'"
        :completed-step-name="'competitorsCompleted'"
      />
      <!-- /Add More Details -->

    </div>
    <!-- /Container -->

    <!-- Main Bottom Bar -->
    <main-bottom-bar
      :back="backNavigation"
      :next="nextNavigation"
      :completed-step-name="'competitorsCompleted'"
      :step-filled="$store.state.idea.storyMode.research.competitors.competitors.length > 0"
      @complete-step="onCompleteStep"
    />
    <!-- /Main Bottom Bar -->

    <!-- Info Guide Drawer -->
    <info-guide-drawer
      type="competitorsExamples"
      :visible="guideVisible"
      @close="guideVisible = false"
    />
    <!-- /Info Guide Drawer -->

    <!-- Dialog Add Competitor -->
    <dialog-add-competitor
      :dialog-add-competitor-visible="dialogAddCompetitorVisible"
      :competitor-action="competitorAction"
      @close-dialog-add-competitor="onCloseDialogAddCompetitor"
    />
    <!-- /Dialog Add Competitor -->

    <!-- Dialog Process Completed -->
    <dialog-process-completed
      :dialog-process-completed-visible="dialogProcessCompletedVisible"
      @close-dialog-process-completed="onCloseDialogProcessCompleted"
    />
    <!-- /Dialog Process Completed -->

    <!-- Dialog Remove Examples -->
    <dialog-remove-examples
      :visible="dialogRemoveExamplesVisible"
      :loading="removeExamplesLoading"
      @no="dialogRemoveExamplesVisible = false"
      @yes="deleteExamples"
    />
    <!-- /Dialog Remove Examples -->

  </div>
  <!-- /Competitors -->

</template>

<script>
import ActionsDropdown from '@/views/Home/StoryMode/Components/ActionsDropdown'
import AddMoreDetails from '@/views/Home/StoryMode/Components/AddMoreDetails'
import AddNewCard from '@/components/_v2/AddNewCard'
import AiBuddyDrawer from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyDrawer.vue'
import BusinessGuideHeader from '@/components/_v2/Common/BusinessGuideHeader'
import BusinessGuideSearch from '@/views/Home/Components/BusinessGuideSearch.vue'
import BusinessPlanAdditionalInfo from '@/views/Home/StoryMode/Components/BusinessPlanAdditionalInfo.vue'
import CompletedOverlay from '@/views/Home/StoryMode/Components/CompletedOverlay'
import DashboardHeader from '@/views/Home/StoryMode/Components/DashboardHeader'
import DialogAddCompetitor from './Dialogs/DialogAddCompetitor'
import DialogProcessCompleted from '@/views/Home/StoryMode/Components/Dialogs/DialogProcessCompleted'
import DialogRemoveExamples from '@/views/Home/StoryMode/Components/DialogRemoveExamples'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import MainBottomBar from '@/views/Home/StoryMode/Components/MainBottomBar/MainBottomBar'
import MainNavigation from '@/views/Home/StoryMode/Components/MainNavigation/MainNavigation'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinEraseTemplate from '@/mixins/eraseTemplate'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinProcessCompleted from '@/mixins/processCompleted'
import ShowAllToggle from '@/views/Home/StoryMode/Components/ShowAllToggle'
import TutorialTooltip from '@/views/Home/Components/TutorialTooltip'
import ViewSwitcher from '@/components/_v2/ViewSwitcher'

import { mapActions, mapGetters } from 'vuex'
import { getImageBySize } from '@/helpers/imageSizeHelper'

import InfoGuideDrawer from '@/views/Home/StoryMode/Components/Example/InfoGuideDrawer.vue'

export default {
  name: 'Competitors',

  components: {
    AiBuddyDrawer,
    InfoGuideDrawer,
    BusinessPlanAdditionalInfo,
    BusinessGuideSearch,
    AddNewCard,
    ActionsDropdown,
    AddMoreDetails,
    BusinessGuideHeader,
    CompletedOverlay,
    DashboardHeader,
    DialogAddCompetitor,
    DialogProcessCompleted,
    DialogRemoveExamples,
    EraseIconButton,
    MainBottomBar,
    MainNavigation,
    ShowAllToggle,
    TutorialTooltip,
    ViewSwitcher
  },

  mixins: [
    MixinProcessCompleted,
    MixinGuide,
    MixinDeleteIdeaExamples,
    MixinIdeaRoles,
    MixinEraseTemplate
  ],

  data () {
    return {
      aiBuddyDrawerVisible: false,
      searchValue: '',
      dialogRemoveExamplesVisible: false,
      removeExamplesLoading: false,
      dialogAddCompetitorVisible: false,
      guideVisible: false,
      competitorAction: null,
      showAllCompetitorsGrid: false,
      showAllCompetitorsList: false,
      dialogProcessCompletedVisible: false,
      examples: []
    }
  },

  computed: {
    ...mapGetters('idea', {
      competitors: 'getCompetitors',
      getStepIsCompleted: 'getStepIsCompleted',
      getHasItemsFromTemplate: 'getHasItemsFromTemplate'
    }),

    statusOfCompleted () {
      return this.getStepIsCompleted('competitorsCompleted')
    },

    isCompetitorsEmpty () {
      return !this.competitors.length
    },

    competitorsToShowGrid () {
      if (this.searchValue !== '' && this.searchValue) {
        return this.filterCompetitors
      }

      return this.showAllCompetitorsGrid === true
        ? this.competitors
        : this.competitors.slice(0, 3)
    },

    competitorsToShowList () {
      if (this.searchValue !== '' && this.searchValue) {
        return this.filterCompetitors
      }

      return this.showAllCompetitorsList === true
        ? this.competitors
        : this.competitors.slice(0, 7)
    },

    filterCompetitors () {
      return this.competitors.filter(item => item.name.toUpperCase().includes(this.searchValue.toUpperCase()))
    },

    hasNotAccess () {
      return !this.canEdit || this.statusOfCompleted
    },

    removeButtonVisible () {
      return this.canEdit && this.getHasItemsFromTemplate('getCompetitors')
    },

    backNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.customer'),
        route: 'business-guide-research-customers'
      }
    },

    nextNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.swotAnalysis'),
        route: 'business-guide-research-swot-analysis'
      }
    }
  },

  created () {
    this.openGuide('competitorGuide')
    this.getCompetitors()
  },

  methods: {
    ...mapActions('idea', [
      'getCompetitors',
      'deleteStoryModeCompetitors',
      'deleteIdeaExamples',
      'updateOrderNumber'
    ]),

    onStartCompetitorsOrder () {
      this.$refs.actionsDropdown.forEach(dropdown => {
        dropdown.$refs.itemDropdown.visible = false
      })
    },

    onChangeCompetitorsOrder (data) {
      this.updateOrderNumber({
        type: 'competitors',
        data,
        oldOrderData: this.competitors,
        mutation: 'setCompetitors'
      })
    },

    checkCompetitorsCard (competitor) {
      if (!this.canEdit) return
      if (this.statusOfCompleted) {
        this.dialogProcessCompletedVisible = true

        return
      }
      if (competitor.id) {
        this.onEditCompetitor(competitor)
      }
    },

    onCloseDialogAddCompetitor () {
      this.dialogAddCompetitorVisible = false
      this.competitorAction = null

      // Workaround to update computed property competitorsToShowGrid & competitorsToShowList
      this.updateCompetitorsView()
    },

    onEraseFromTemplate () {
      if (this.statusOfCompleted) {
        this.openedDialogProcessCompleted()

        return
      }
      this.dialogRemoveExamplesVisible = true
    },

    updateCompetitorsView () {
      this.showAllCompetitorsList = !this.showAllCompetitorsList
      this.showAllCompetitorsList = !this.showAllCompetitorsList
      this.showAllCompetitorsGrid = !this.showAllCompetitorsGrid
      this.showAllCompetitorsGrid = !this.showAllCompetitorsGrid
    },

    onActivateView (view) {
      this.$store.commit('itemsView/setCompetitorsView', view)
    },

    onEditCompetitor (competitor) {
      this.competitorAction = Object.assign({}, competitor)
      this.dialogAddCompetitorVisible = true
    },

    onCopyCompetitor (competitor) {
      this.competitorAction = Object.assign({}, competitor)
      this.competitorAction.id = 0
      this.dialogAddCompetitorVisible = true
    },

    onDeleteCompetitor (competitor) {
      this.deleteStoryModeCompetitors(competitor.id)

      // Return to grid view, if there are no competitors
      if (this.$store.state.idea.storyMode.research.competitors.competitors.length === 0) {
        this.onActivateView('grid')
      }
    },

    cardImageBySize (image, size) {
      return image ? 'background-image: url(' + getImageBySize(image.sizes, size) + ')' : ''
    },

    onGuideExampleVisible () {
      this.guideVisible = !this.guideVisible
    },

    calculateAdvantageStrength (value) {
      if (value <= 30) {
        return this.$t('pages.businessGuide.competitors.addDialog.competitiveAdvantages.poor')
      } else if (value <= 60) {
        return this.$t('pages.businessGuide.competitors.addDialog.competitiveAdvantages.fair')
      } else if (value <= 90) {
        return this.$t('pages.businessGuide.competitors.addDialog.competitiveAdvantages.good')
      }

      return this.$t('pages.businessGuide.competitors.addDialog.competitiveAdvantages.excellent')
    },

    getCompetitorLevel (competitor) {
      // noinspection JSUnresolvedVariable
      const advantageLevel = competitor.advantageValue1 + competitor.advantageValue2 + competitor.advantageValue3 +
        competitor.advantageValue4 + competitor.advantageValue5

      if (advantageLevel <= 200) {
        return this.$t('low')
      } else if (advantageLevel <= 400) {
        return this.$t('medium')
      }

      return this.$t('high')
    },

    openDialog () {
      if (!this.canEdit) return
      this.dialogAddCompetitorVisible = true
    },

    deleteExamples () {
      this.removeExamplesLoading = true
      this.deleteIdeaExamples('competitors')
        .then(response => {
          const { type, ids } = response
          this.deleteFromState({ type, ids })
          this.dialogRemoveExamplesVisible = false
          this.removeExamplesLoading = false
        }).catch(_ => { this.removeExamplesLoading = false })
    }
  }
}
</script>

<style scoped>
  #story-mode-competitors {
    margin-left: 0;
  }
</style>
