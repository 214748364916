<template>

  <!-- Swot Analysis -->
  <div id="story-mode-swot-analysis-filled">

    <!-- Main Navigation -->
    <main-navigation />
    <!-- /Main Navigation -->

    <!-- Container -->
    <div class="container-1200">

      <!-- Content -->
      <div
        class="content"
        :class="{ 'position-relative': statusOfCompleted }"
      >
        <v-app>
          <!-- Header -->
          <header class="page-header">

            <!-- Dashboard Header -->
            <dashboard-header
              :class="{ 'stamp': statusOfCompleted }"
              :title="$t('pages.businessGuide.swotAnalysis.title')"
              :guide-visible="guideVisible"
              :completed-step-name="'swotAnalysisCompleted'"
              hide-quick-tour-button
              @toggle-guide="onGuideExampleVisible"
              @open-comp-dialog="openedDialogProcessCompleted"
              @open-info="guideVisible = true"
              @open-ai-buddy-drawer="aiBuddyDrawerVisible = true"
            />
            <!-- /Dashboard Header -->

            <!-- Divider -->
            <hr>
            <!-- /Divider -->

          </header>
          <!-- /Header -->

          <!-- Ai Buddy Drawer -->
          <ai-buddy-drawer
            :visible="aiBuddyDrawerVisible"
            section-name="SwotAnalysis"
            help-me-write
            type="swot-analysis"
            @close="aiBuddyDrawerVisible = false"
          />
          <!-- /Ai Buddy Drawer -->
        </v-app>

        <!-- Height Holder For Sticky Header -->
        <div class="height-holder d-none d-md-block" />
        <!-- /Height Holder For Sticky Header -->

        <div v-if="loading" class="why-and-what-loader">
          <loader />
        </div>

        <div v-else class="mb-5">
          <!-- Business plan additional info -->
          <business-plan-additional-info
            completed-step-name="swotAnalysisCompleted"
            section-name="SwotAnalysis"
            show-ai-buddy
            @ai-buddy-open="aiBuddyDrawerVisible = true"
          />
          <!-- /Business plan additional info -->

          <!-- Header -->
          <business-guide-header class="mb-3 mb-md-0 ">
            <template #left>
              <p class="text-lead xl m-0 my-md-3 o-100">
                {{ $t('pages.businessGuide.swotAnalysis.description') }}
              </p>
            </template>
            <template #right>
              <erase-icon-button
                v-if="removeButtonVisible"
                @click="onEraseFromTemplate"
              />
            </template>
          </business-guide-header>
          <!-- /Header -->

          <!-- Swot Analysis Elements -->
          <div class="swot-cards-wrapper">
            <el-row :gutter="20" class="d-md-flex">

              <!-- The Strengths Section Box -->
              <el-col :sm="12" class="mb-3">

                <!-- Strengths Section -->
                <swot-analysis-elements
                  :type="'strengths'"
                  step-name="swotAnalysisCompleted"
                  :title="strengthsSection.title"
                  :description="strengthsSection.description"
                  :text="strengths"
                  :matching="!!weaknesses && !!opportunities && !!threats"
                  @click="dialogAddStrengthsVisible = true"
                />
                <!-- /Strengths Section -->

                <!-- Dialog Add Strengths Section -->
                <dialog-add-swot-elements
                  v-if="strengths !== null"
                  :dialog-type="'strengths'"
                  :text="strengths"
                  :dialog-add-swot-elements-visible="dialogAddStrengthsVisible"
                  :title="strengthsSection.title"
                  :description="strengthsSection.description"
                  @on-save="updateStrengths"
                  @discard="dialogAddStrengthsVisible = false"
                />
                <!-- /Dialog Add Strengths Section -->

              </el-col>
              <!-- /The Strengths Section Box -->

              <!-- The Weaknesses Section Box -->
              <el-col :sm="12" class="mb-3">

                <!-- Weaknesses Section -->
                <swot-analysis-elements
                  :type="'weaknesses'"
                  step-name="swotAnalysisCompleted"
                  :title="weaknessesSection.title"
                  :description="weaknessesSection.description"
                  :text="weaknesses"
                  :matching="!!strengths && !!opportunities && !!threats"
                  @click="dialogAddWeaknessesVisible = true"
                />
                <!-- /Weaknesses Section -->

                <!-- Dialog Add weaknesses Section -->
                <dialog-add-swot-elements
                  v-if="weaknesses !== null"
                  :dialog-type="'weaknesses'"
                  :text="weaknesses"
                  :dialog-add-swot-elements-visible="dialogAddWeaknessesVisible"
                  :title="weaknessesSection.title"
                  :description="weaknessesSection.description"
                  @on-save="updateWeaknesses"
                  @discard="dialogAddWeaknessesVisible = false"
                />
                <!-- /Dialog Add Weaknesses Section -->

              </el-col>
              <!-- /The Weaknesses Section Box -->
            </el-row>
          </div>

          <div class="swot-cards-wrapper">

            <el-row :gutter="20" class="d-md-flex">

              <!-- The Opportunities Section Box -->
              <el-col :sm="12" class="mb-3">

                <!-- Opportunities Section -->
                <swot-analysis-elements
                  :type="'opportunities'"
                  step-name="swotAnalysisCompleted"
                  :title="opportunitiesSection.title"
                  :description="opportunitiesSection.description"
                  :text="opportunities"
                  :matching="!!strengths && !!weaknesses && !!threats"
                  @click="dialogAddOpportunitiesVisible = true"
                />
                <!-- /Opportunities Section -->

                <!-- Dialog Add Opportunities Section -->
                <dialog-add-swot-elements
                  v-if="opportunities !== null"
                  :dialog-type="'opportunities'"
                  :text="opportunities"
                  :dialog-add-swot-elements-visible="dialogAddOpportunitiesVisible"
                  :title="opportunitiesSection.title"
                  :description="opportunitiesSection.description"
                  @on-save="updateOpportunities"
                  @discard="dialogAddOpportunitiesVisible = false"
                />
                <!-- /Dialog Add Opportunities Section -->

              </el-col>
              <!-- /The Opportunities Section Box -->

              <!-- The Threats Section Box -->
              <el-col :sm="12" class="mb-3">

                <!-- Threats Section -->
                <swot-analysis-elements
                  :type="'threats'"
                  step-name="swotAnalysisCompleted"
                  :title="threatsSection.title"
                  :description="threatsSection.description"
                  :text="threats"
                  :matching="!!strengths && !!weaknesses && !!opportunities"
                  @click="dialogAddThreatsVisible = true"
                />
                <!-- /Threats Section -->

                <!-- Dialog Add Threats Section -->
                <dialog-add-swot-elements
                  v-if="threats !== null"
                  :dialog-type="'threats'"
                  :text="threats"
                  :dialog-add-swot-elements-visible="dialogAddThreatsVisible"
                  :title="threatsSection.title"
                  :description="threatsSection.description"
                  @on-save="updateThreats"
                  @discard="dialogAddThreatsVisible = false"
                />
                <!-- /Dialog Add Threats Section -->

              </el-col>
              <!-- /The Opportunities Section Box -->

            </el-row>
          </div>
          <!-- /Swot Analysis Elements -->
        </div>

      </div>
      <!-- Content -->

      <!-- Add More Details -->
      <add-more-details
        :category="'research'"
        :step="'swot-analysis'"
        :concept-name="'swotAnalysisMoreDetails'"
        :completed-step-name="'swotAnalysisCompleted'"
      />
      <!-- /Add More Details -->

    </div>
    <!-- /Container -->

    <!-- Main Bottom Bar -->
    <main-bottom-bar
      :back="backNavigation"
      :next="nextNavigation"
      :completed-step-name="'swotAnalysisCompleted'"
      :step-filled="!!strengths && !!weaknesses && !!opportunities && !!threats"
      @complete-step="onCompleteStep"
    />
    <!-- /Main Bottom Bar -->

    <!-- Info Guide Drawer -->
    <info-guide-drawer
      type="swotAnalysisExamples"
      :visible="guideVisible"
      @close="guideVisible = false"
    />
    <!-- /Info Guide Drawer -->

    <!-- Dialog Process Completed -->
    <dialog-process-completed
      :dialog-process-completed-visible="dialogProcessCompletedVisible"
      @close-dialog-process-completed="onCloseDialogProcessCompleted"
    />
    <!-- /Dialog Process Completed -->

    <!-- Dialog Remove Examples -->
    <dialog-remove-examples
      :visible="dialogRemoveExamplesVisible"
      :loading="removeExamplesLoading"
      @no="dialogRemoveExamplesVisible = false"
      @yes="deleteExamples"
    />
    <!-- /Dialog Remove Examples -->

  </div>
  <!-- Swot Analysis -->
</template>

<script>
import AddMoreDetails from '@/views/Home/StoryMode/Components/AddMoreDetails'
import AiBuddyDrawer from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyDrawer.vue'
import BusinessGuideHeader from '@/components/_v2/Common/BusinessGuideHeader'
import BusinessPlanAdditionalInfo from '@/views/Home/StoryMode/Components/BusinessPlanAdditionalInfo.vue'
import DashboardHeader from '@/views/Home/StoryMode/Components/DashboardHeader'
import DialogAddSwotElements from '@/views/Home/StoryMode/Research/SwotAnalysis/Dialogs/DialogAddSwotElements'
import DialogProcessCompleted from '@/views/Home/StoryMode/Components/Dialogs/DialogProcessCompleted'
import DialogRemoveExamples from '@/views/Home/StoryMode/Components/DialogRemoveExamples'
import Loader from '@/components/Loader'
import MainBottomBar from '@/views/Home/StoryMode/Components/MainBottomBar/MainBottomBar'
import MainNavigation from '@/views/Home/StoryMode/Components/MainNavigation/MainNavigation'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinEraseTemplate from '@/mixins/eraseTemplate'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinProcessCompleted from '@/mixins/processCompleted'
import SwotAnalysisElements from '@/views/Home/StoryMode/Research/SwotAnalysis/SwotAnalysisElements'

import { mapActions, mapGetters } from 'vuex'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import InfoGuideDrawer from '@/views/Home/StoryMode/Components/Example/InfoGuideDrawer.vue'

export default {
  name: 'SwotAnalysis',

  components: {
    AiBuddyDrawer,
    InfoGuideDrawer,
    BusinessPlanAdditionalInfo,
    AddMoreDetails,
    BusinessGuideHeader,
    DashboardHeader,
    DialogAddSwotElements,
    DialogProcessCompleted,
    DialogRemoveExamples,
    EraseIconButton,
    Loader,
    MainBottomBar,
    MainNavigation,
    SwotAnalysisElements
  },

  mixins: [
    MixinGuide,
    MixinProcessCompleted,
    MixinDeleteIdeaExamples,
    MixinIdeaRoles,
    MixinEraseTemplate
  ],

  data () {
    return {
      aiBuddyDrawerVisible: false,
      dialogRemoveExamplesVisible: false,
      removeExamplesLoading: false,
      dialogAddStrengthsVisible: false,
      dialogAddWeaknessesVisible: false,
      dialogAddOpportunitiesVisible: false,
      dialogAddThreatsVisible: false,
      loading: false,
      strengthsSection: {
        title: this.$t('pages.businessGuide.swotAnalysis.elements.strengths.title'),
        description: this.$t('pages.businessGuide.swotAnalysis.elements.strengths.text')
      },
      weaknessesSection: {
        title: this.$t('pages.businessGuide.swotAnalysis.elements.weaknesses.title'),
        description: this.$t('pages.businessGuide.swotAnalysis.elements.weaknesses.text')
      },
      opportunitiesSection: {
        title: this.$t('pages.businessGuide.swotAnalysis.elements.opportunities.title'),
        description: this.$t('pages.businessGuide.swotAnalysis.elements.opportunities.text')
      },
      threatsSection: {
        title: this.$t('pages.businessGuide.swotAnalysis.elements.threats.title'),
        description: this.$t('pages.businessGuide.swotAnalysis.elements.threats.text')
      },
      guideVisible: false
    }
  },

  computed: {
    ...mapGetters('idea', {
      getSwotAnalysisStrengths: 'getSwotAnalysisStrengths',
      getSwotAnalysisWeaknesses: 'getSwotAnalysisWeaknesses',
      getSwotAnalysisOpportunities: 'getSwotAnalysisOpportunities',
      getSwotAnalysisThreats: 'getSwotAnalysisThreats',
      getStepIsCompleted: 'getStepIsCompleted',
      hasItemsFromTemplate: 'getSwotAnalysisHasItemsFromTemplate'
    }),

    strengths: {
      get () {
        return this.getSwotAnalysisStrengths
      },
      set (newVal) {
        return newVal
      }
    },

    weaknesses: {
      get () {
        return this.getSwotAnalysisWeaknesses
      },
      set (newVal) {
        return newVal
      }
    },

    opportunities: {
      get () {
        return this.getSwotAnalysisOpportunities
      },
      set (newVal) {
        return newVal
      }
    },

    threats: {
      get () {
        return this.getSwotAnalysisThreats
      },
      set (newVal) {
        return newVal
      }
    },

    statusOfCompleted () {
      return this.getStepIsCompleted('swotAnalysisCompleted')
    },

    removeButtonVisible () {
      return this.canEdit && this.hasItemsFromTemplate
    },

    backNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.competitors'),
        route: 'business-guide-research-competitors'
      }
    },

    nextNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.marketPotential'),
        route: 'business-guide-research-market-potential'
      }
    }
  },

  async created () {
    this.loading = true
    await this.getSwotAnalysis()
    this.loading = false
    this.openGuide('swotAnalysisGuide')
  },

  methods: {
    ...mapActions('idea', [
      'getSwotAnalysis',
      'deleteIdeaExamples'
    ]),

    updateStrengths (text) {
      this.strengths = text
      this.$nextTick(() => {
        this.scrollableStrengthsCard()
      })
    },

    updateWeaknesses (text) {
      this.weaknesses = text
      this.$nextTick(() => {
        this.scrollableWeaknessesCard()
      })
    },
    updateOpportunities (text) {
      this.opportunities = text
      this.$nextTick(() => {
        this.scrollableOpportunitiesCard()
      })
    },

    updateThreats (text) {
      this.threats = text
      this.$nextTick(() => {
        this.scrollableThreatsCard()
      })
    },

    scrollableStrengthsCard () {
      const strengthsCardText = document.querySelector('.strengths .clamped')
      const strengthsCardTextInner = document.querySelector('.strengths .clamped .inner-clamped')

      if (strengthsCardText) {
        if ((strengthsCardTextInner.offsetHeight - 20) > strengthsCardText.offsetHeight) {
          strengthsCardText.classList.add('scrollable')
        } else {
          strengthsCardText.classList.remove('scrollable')
        }
      }
    },

    scrollableWeaknessesCard () {
      const weaknessesCardText = document.querySelector('.weaknesses .clamped')
      const weaknessesCardTextInner = document.querySelector('.weaknesses .clamped .inner-clamped')

      if (weaknessesCardText) {
        if ((weaknessesCardTextInner.offsetHeight - 20) > weaknessesCardText.offsetHeight) {
          weaknessesCardText.classList.add('scrollable')
        } else {
          weaknessesCardText.classList.remove('scrollable')
        }
      }
    },

    scrollableOpportunitiesCard () {
      const opportunitiesCardText = document.querySelector('.opportunities .clamped')
      const opportunitiesCardTextInner = document.querySelector('.opportunities .clamped .inner-clamped')

      if (opportunitiesCardText) {
        if ((opportunitiesCardTextInner.offsetHeight - 20) > opportunitiesCardText.offsetHeight) {
          opportunitiesCardText.classList.add('scrollable')
        } else {
          opportunitiesCardText.classList.remove('scrollable')
        }
      }
    },

    scrollableThreatsCard () {
      const threatsCardText = document.querySelector('.threats .clamped')
      const threatsCardTextInner = document.querySelector('.threats .clamped .inner-clamped')

      if (threatsCardText) {
        if ((threatsCardTextInner.offsetHeight - 20) > threatsCardText.offsetHeight) {
          threatsCardText.classList.add('scrollable')
        } else {
          threatsCardText.classList.remove('scrollable')
        }
      }
    },

    onGuideExampleVisible () {
      this.guideVisible = !this.guideVisible
    },

    onEraseFromTemplate () {
      if (this.statusOfCompleted) {
        this.openedDialogProcessCompleted()

        return
      }
      this.dialogRemoveExamplesVisible = true
    },

    deleteExamples () {
      this.removeExamplesLoading = true
      Promise.all([
        this.deleteIdeaExamples('swot-analysis-strengths'),
        this.deleteIdeaExamples('swot-analysis-weaknesses'),
        this.deleteIdeaExamples('swot-analysis-opportunities'),
        this.deleteIdeaExamples('swot-analysis-threats')
      ]).then(response => {
        response.forEach(item => {
          const { type, ids } = item
          this.deleteFromState({ type, ids })
        })
        this.dialogRemoveExamplesVisible = false
        this.removeExamplesLoading = false
      }).catch(_ => { this.removeExamplesLoading = false })
    }
  }

}
</script>

<style scoped>

</style>
