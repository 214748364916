<template>
  <!-- Dialog Add Problem -->
  <div id="dialog-story-mode-add-problem">
    <ib-dialog
      empty-header
      :visible="dialogAddSwotElementsVisible"
      :fullscreen="true"
      @close="close"
    >
      <ib-dialog-container class="content-wrapper px-1" max-width="1440px">

        <!-- Header -->
        <header>

          <!-- Title -->
          <h2 class="text-xl-left">
            {{ title }}
          </h2>
          <!-- /Title -->

          <!-- Description -->
          <p class="text-xl-left ml-xl-0">
            {{ description }}
          </p>
          <!-- /Description -->

        </header>
        <!-- /Header -->

        <!-- Ai Buddy -->
        <ai-buddy
          :type="suggestionsType"
          class="mb-4 d-block d-xl-none"
          @suggestion="setSuggestion"
        />
        <!-- Ai Buddy -->

        <el-row :gutter="30">

          <!-- Left Side -->
          <el-col :xl="14">
            <!-- Quill Wrapper -->
            <div class="quill-wrapper">
              <quill-editor
                ref="quillio"
                v-model="content"
                :options="editorOptions"
                @change="inputTimeOut"
              />
            </div>
            <!-- /Quill Wrapper -->

            <!-- Divider -->
            <hr>
            <!-- /Divider -->

            <!-- Action Buttons -->
            <div class="bottom-flex d-flex justify-content-end">

              <!-- Save -->
              <el-button
                :loading="loading"
                class="small"
                @click="onSave"
              >
                {{ $t('save') }}
              </el-button>
              <!-- /Save -->

            </div>
            <!-- /Action Buttons -->
          </el-col>
          <!-- /Left Side -->

          <!-- Right Side -->
          <el-col :xl="10">
            <!-- Ai Buddy -->
            <ai-buddy
              :type="suggestionsType"
              class="mb-4 d-none d-xl-block"
              @suggestion="setSuggestion"
            />
            <!-- Ai Buddy -->
          </el-col>
          <!-- /Right Side -->

        </el-row>

      </ib-dialog-container>
    </ib-dialog>
  </div>
  <!-- /Dialog Add Problem -->
</template>

<script>
import quillPasteSmartOptions from '@/options/quillPasteSmartOptions'

import AiBuddy from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddy.vue'
import { mapActions } from 'vuex'
import { quillEditor } from 'vue-quill-editor'
import { validateImage } from '@/helpers/imageHelper'

export default {
  name: 'DialogAddSwotElements',

  components: {
    AiBuddy,
    quillEditor
  },

  props: {
    dialogAddSwotElementsVisible: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    dialogType: {
      default: '',
      type: String
    }
  },

  data () {
    return {
      editorOptions: {
        modules: {
          ...quillPasteSmartOptions,
          toolbar: {
            container: [
              ['bold', 'italic', 'underline'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['blockquote'],
              ['link', 'image']
            ],
            handlers: {
              image: this.imageButtonClickHandler
            }
          }
        },
        placeholder: this.$t('placeholderDescribe')
      },
      form: {
        text: this.text
      },
      loading: false
    }
  },

  computed: {
    content: {
      get () {
        return this.text
      },
      set (val) {
        this.form.text = val
      }
    },

    suggestionsType () {
      switch (this.dialogType) {
        case 'strengths':
          return 'swot-strengths'
        case 'weaknesses':
          return 'swot-weaknesses'
        case 'opportunities':
          return 'swot-opportunities'
        case 'threats':
          return 'swot-threats'
        default:
          return ''
      }
    }
  },

  methods: {
    ...mapActions('idea', ['uploadImage']),

    inputTimeOut ({ text }) {
      const cEditor = this.$refs.quillio.quill
      if (cEditor.getLength() > 1501) {
        alert('Maximum number of characters is 1500')
        if (cEditor.getSelection() === null) {
          cEditor.deleteText(1500, cEditor.getLength())
        } else {
          cEditor.deleteText(cEditor.getSelection().index - 1, 1)
        }
      }
    },

    onSave () {
      if (this.dialogType === 'strengths') {
        this.saveStrengths()
      }
      if (this.dialogType === 'weaknesses') {
        this.saveWeaknesses()
      }

      if (this.dialogType === 'opportunities') {
        this.saveOpportunities()
      }

      if (this.dialogType === 'threats') {
        this.saveThreats()
      }
    },

    setContent () {
      const editor = this.$refs.quillio.quill
      editor.setContents(editor.clipboard.convert(this.text), 'silent')
    },

    setSuggestion (suggestion) {
      this.form.text = suggestion
      const editor = this.$refs.quillio.quill
      editor.setText(suggestion)
    },

    saveStrengths () {
      this.loading = true
      this.$http.post(`story-mode/research/swot/strengths/${this.$store.state.idea.id}`, this.form)
        .then((response) => {
          if (response.status === 201) {
            const strength = response.data.payload
            this.$store.commit('idea/setSwotAnalysisStrengths', this.form.text)
            this.$store.commit('idea/setSwotAnalysisFromTemplate', {
              type: 'strengthsFromTemplate',
              value: !strength.isTouched && strength.isFromTemplate
            })
            this.$emit('on-save', this.form.text)
            this.close()
          }
        }).finally(() => {
          this.loading = false
        })
    },

    saveWeaknesses () {
      this.loading = true
      this.$http.post(`story-mode/research/swot/weaknesses/${this.$store.state.idea.id}`, this.form)
        .then((response) => {
          if (response.status === 201) {
            const weakness = response.data.payload
            this.$store.commit('idea/setSwotAnalysisWeaknesses', this.form.text)
            this.$store.commit('idea/setSwotAnalysisFromTemplate', {
              type: 'weaknessesFromTemplate',
              value: !weakness.isTouched && weakness.isFromTemplate
            })
            this.$emit('on-save', this.form.text)
            this.close()
          }
        }).finally(() => {
          this.loading = false
        })
    },

    saveOpportunities () {
      this.loading = true
      this.$http.post(`story-mode/research/swot/opportunities/${this.$store.state.idea.id}`, this.form)
        .then((response) => {
          if (response.status === 201) {
            const opportunity = response.data.payload
            this.$store.commit('idea/setSwotAnalysisOpportunities', this.form.text)
            this.$store.commit('idea/setSwotAnalysisFromTemplate', {
              type: 'opportunitiesFromTemplate',
              value: !opportunity.isTouched && opportunity.isFromTemplate
            })
            this.$emit('on-save', this.form.text)
            this.close()
          }
        }).finally(() => {
          this.loading = false
        })
    },

    saveThreats () {
      this.loading = true
      this.$http.post(`story-mode/research/swot/threats/${this.$store.state.idea.id}`, this.form)
        .then((response) => {
          if (response.status === 201) {
            const threat = response.data.payload
            this.$store.commit('idea/setSwotAnalysisThreats', this.form.text)
            this.$store.commit('idea/setSwotAnalysisFromTemplate', {
              type: 'threatsFromTemplate',
              value: !threat.isTouched && threat.isFromTemplate
            })
            this.$emit('on-save', this.form.text)
            this.close()
          }
        }).finally(() => {
          this.loading = false
        })
    },

    close () {
      this.form.text = this.text
      setTimeout(() => {
        this.setContent()
      }, 100)
      this.$emit('discard')
    },

    imageButtonClickHandler () {
      const cEditor = this.$refs.quillio.quill
      if (cEditor.getLength() > 1500) {
        alert('You have reached the maximum content length, you can not upload any more images!')
      } else {
        // Add a file input element to the document, then click it (open file).
        const input = document.createElement('input')
        input.hidden = true
        input.setAttribute('type', 'file')
        input.click()
        document.body.appendChild(input)

        // Once file is selected.
        input.onchange = () => {
          const file = input.files[0]
          const cEditor = this.$refs.quillio.quill
          const images = cEditor.root.getElementsByTagName('img').length

          if (!validateImage(file, images)) return

          // Create form.
          const formData = new FormData()
          formData.append('image', file)

          this.uploadImage({ formData, type: 'swot-analysis' })
            .then(response => {
              // Get the current cursor position.
              const range = cEditor.getSelection()
              // Insert the image via URL where the cursor is.
              cEditor.insertEmbed(range.index, 'image', response.url)
            })
        }
      }
    }
  }
}
</script>
